@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}

.navbar {
  z-index: 200;
  top: 0;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.contact {
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  border: 1px rgb(97, 97, 97) solid;
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
  transition: 0.2s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.contact:hover {
  background: rgba(255, 255, 255, 0.377);
}

.navMenu {
  padding-top: 20px;
  display: flex;
  width: 50%;
  height: 50px;
  align-items: center;
  justify-content: space-between;
}

.template {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  background-image: url(https://i.postimg.cc/R0ytzzH9/logo.png);
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-top: 10px;
}

.navItem {
  cursor: pointer;
  border-radius: 7px;
  padding: 5px 8px 5px 8px;
  transition: 0.2s;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.navItem:hover {
  box-shadow: rgba(0, 0, 0, 0.13) 0px 4px 12px;
}

.logoTempl {
  align-items: center;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.titleName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  word-spacing: -5px;
  cursor: pointer;
  font-size: 25px;
  padding-left: 10px;
  font-weight: 600;
  color: #3d3a89;
  font-family: 'Courier New', Courier, monospace;
}

@media (max-width: 1700px) {
  .navMenu {
    width: 60%;
  }
  .template {
    width: 35%;
  }
}
