@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');

.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 45%;
  min-height: 330px;
  justify-content: space-between;
  align-items: flex-end;
  border-radius: 15px;

  z-index: 2;
}


.conteiner2 {
  width: 100%;
}

.picGirl {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.pic {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.header {
  font-size: 13px;
  font-family: 'Rubik', sans-serif;
  color: rgb(233, 82, 12);
  font-weight: 500;
}

.slogan {
  padding: 5px 10px 5px 10px;
  margin-top: 20px;
  font-size: 30px;
  font-weight: 400;
  color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: end;
  margin-right: 20px;
  z-index: 2;
  text-align: end;
  border-radius: 10px;
}

.subs {
  margin-right: 20px;
  width: 250px;
  margin-bottom: 10px;
  font-size: 14px;
  color: rgb(255, 255, 255);
  line-height: 16px;
  background-color: #49494988;
  border-radius: 10px;
  padding: 10px;
  text-align: start;
  z-index: 2;
}

.writeButton {
  width: 250px;
  background: linear-gradient(-45deg, #edecff, #ffffff71);
  padding: 5px 10px 6px 10px;
  border-radius: 10px;
  color: rgb(2, 2, 2);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  z-index: 5;
  margin-bottom: 20px;
  margin-right: 20px;
}



@media (max-width: 1700px) {
  .banner {
    width: 53%;
  }
  .pic {
    width: 100%;
  }
}
