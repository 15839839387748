.title {
  margin-top: 20px;
  display: flex;
  width: 55%;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 30px;
  position: relative;
}

.pic {
  position: absolute;
  height: 140px;
  right: 0;
  z-index: 1;
}
