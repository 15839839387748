@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');

.sendForm {
  margin-top: 30px;
  margin-bottom: 20px;
  background: rgb(237 236 255);
  height: 230px;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 70px 10px 10px 10px;
  position: relative;
  background-image: url(https://i.postimg.cc/7hHpf4rJ/mask.png);
  background-size: 40%;
  background-repeat:no-repeat ;
  background-position: left 0% bottom 0%;
}

.pic {
  position: absolute;
  height: 40px;
  right: -10px;
  top: -10px;
}

.data {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dataPic {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

input {
  text-align: left;
  border: inherit;
  height: 35px;
  width: 250px;
  font-size: 16px;
  margin: 0px 5px 0px 5px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border-radius: 3px;
}

.sendButt {
  cursor: pointer;
  border: inherit;
  height: 37px;
  width: 100px;
  border-radius: 5px;
  background: linear-gradient(-45deg, #ff8661, #fcb252);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  color: white;
  font-family: 'Rubik', sans-serif;
  font-size: 15px;
}

.title {
  font-size: 17px;
  color: rgb(104, 91, 129);
  font-family: 'Rubik', sans-serif;
  margin-bottom: 40px;
}

@media (max-width: 1700px) {
  .sendForm {
    width: 53%;
  }
}