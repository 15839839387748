.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.contactInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 45%;
}

.items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 90%;
}

.box1 {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  font-size: 14px;
  color: rgb(41, 41, 41);
}
.box2 {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  font-size: 14px;
  color: rgb(41, 41, 41);
}
.box3 {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  font-size: 14px;
  color: rgb(41, 41, 41);
}

.ur {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  text-align: left;
  width: 86.5%;
  font-size: 14px;
  color: rgb(41, 41, 41);
}

.ur2 {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
  width: 86.5%;
  font-size: 14px;
  color: rgb(41, 41, 41);
}

.title {
  font-size: 22px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
}

.title2 {
  font-size: 22px;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 10px;
}

.cert {
  width: 600px;
}

.buttonCert {
  margin-top: 10px;
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: 'JetBrains Mono', monospace;
  height: 30px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 14px;
}

.buttonCert:focus {
  box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.buttonCert:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.buttonCert:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}


@media (max-width: 1700px) {
  .contactInfo {
    width: 53%;
  }
}