.footerMain {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: rgb(247, 247, 247);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 45%;
}

.pic {
  height: 45px;
}

.picContact {
  height: 32px;
  margin: 15px 5px 10px 5px;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.name {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy {
  font-size: 12px;
  color: gray;
}

.ad {
  text-align: left;
  font-size: 12px;
  color: gray;
}
