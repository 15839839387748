.cards {
  display: flex;
  width: 45%;
  align-items: flex-start;
  justify-content: space-between;
}

.picGirl2 {
  margin-top: 7px;
  margin-bottom: 8px;
  width: 150px;
}

.card1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 10px 10px 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  z-index: 1;
  background-color: white;
}

.cardDisc1 {
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 150px;
  height: 68px;
  font-size: 12px;
  overflow: hidden;
  transition: overflow 1s;
  color: rgb(110, 110, 110);
}

.cardDisc1:hover {
  overflow: auto;
  transition: overflow 1s;
  height: fit-content;
}


@media (max-width: 1700px) {
  .cards {
    width: 53%;
  }
}
