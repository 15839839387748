.travel {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-size: 22px;
  font-weight: 500;
}

.picGirl {
  height: 150px;
}

.cards {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 45%;
}

.card {
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 10px 20px 10px 20px;
  padding: 10px 10px 0px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 10px;
  color: rgb(53, 53, 53);
}

.subs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  font-weight: 500;
}

.wrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 38.5%;
  margin-bottom: 40px;
}

.wrapperItem1 {
  display: flex;
  position: relative;
  text-align: start;
  width: 44%;
  font-size: 14px;
}

.wrapperItem2 {
  display: flex;
  position: relative;
  text-align: start;
  width: 56%;
  font-size: 14px;
}

@media (max-width: 1700px) {
  .cards {
    width: 53%;
  }
  .wrapper {
    width: 48.5%;
  }
}
